/* CSS
 |--------------------------------------------------------------------------
 | spreadsheet css
 | 2015-11-10
 |--------------------------------------------------------------------------
 |
 | The responsive, reactive paginated data tables are editable
 | however we don't want them to 'look' like forms so we
 | remove the boarders, etc.
 |
 */

.ngDataTable input {
    border: none;
    background-color: transparent;
}

.ngDataTable input:focus {
    outline: 0;
}

.ngDataTable .fa-sort {
    position: relative;
    float: right;
    /*top: -15px;*/
    left: -7px;
    z-index: 100;

    width: 0px;
    height: 0px;
    overflow: visible;

    font-size: 18px;
}

.pagination-next, .pagination-prev{
    font-weight: bold;
}

/*
 |--------------------------------------------------------------------------
 | longModalTextEditor
 | 2015-11-12
 |--------------------------------------------------------------------------
 */
textarea.longModalTextEditor {
    border: none;
    background-color: transparent;

    width: 100%;
    height: 250px;
}

/*--------------------------------------------------------------------------
 * 2018-03-16
 */
.ng-hide.ng-hide-animate {
    display: none !important;
}

.ng-animate.no-animate {
    transition: 0s none;
    -webkit-transition: 0s none;
    animation: 0s none;
    -webkit-animation: 0s none;
}

/*--------------------------------------------------------------------------
 * 2018-06-26
 */
.ngDataTable tfoot *{
    font-weight: bold;
}
