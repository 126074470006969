@media (max-width: 767px){

    .sidebar{
        float: none;
        width: 100%;

    }
    .sidebar .sidebar-dropdown{
        display: block;
    }
    .sidebar #nav{
        display: none;
    }
    .mainbar{
        float: none;
        margin:0px;
        border:0px;
    }

    .navbar-form{
        margin:0px !important;
        /*padding:0px !important;*/
    }

    .navbar-nav .open .dropdown-menu {
        position:absolute;
        background-color: #fefefe
    }

/*    .navbar li a {
        padding: 0px 5px 0px 0px;
    }*/
}

@media (min-width: 768px){
    .form{
        margin: 0px !important;
    }
    form .control-group{
        margin:0px !important;
    }
    form .control-label{
        float: none !important;
        width: auto !important;
        text-align: left !important;
    }
    form .controls{
        float: none !important;
        margin-left: 0px !important;
    }
    form .form-actions{
        padding-left: 0px !important;
    }

    header{
        padding-top:10px;
    }

    header .nav.nav-pills{
        margin-top: 30px;
        margin-bottom: 20px;
        margin-left:20px;
    }
    header .header-data {
        margin-top: 15px;
        margin-left: 20px;
    }

    .mainbar{
        margin-left: 230px;
        padding-right: 230px;
    }
}

.navbar-header + nav{
    display: block !important;
}

.navbar-form{
    max-width: 75%;
    float: left;
}