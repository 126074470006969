/*--------------------------------------------------------------------------
 * spinning glyphicons
 * 2016-01-22
 */

.spinning {
    animation: spin 1.25s infinite linear;
    -webkit-animation: spin 1.25s infinite linear;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/*--------------------------------------------------------------------------
 * That throbbing she's so fond of.
 * 2016-01-24
 */

.throbbing {
    animation-name: throb;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 0.75s;
    animation-direction: alternate;
}

@-webkit-keyframes throb {
    0% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.25;
    }
}

@keyframes throb {
    0% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.25;
    }
}

/*--------------------------------------------------------------------------
 * Glyphicon in messages is too close to words.
 * 2016-01-26
 */
.alert p > span.glyphicon,
.alert p > i.glyphicon {
    padding-right: 12px;
}

/*--------------------------------------------------------------------------
 * Search
 * 2018-02-18
 */

.search-result-card img{
    margin-right: 5px;
}

/*--------------------------------------------------------------------------
 * selectize selectize.js
 * 2016-01-31
 */

.selectize-input {
    margin-bottom: -7px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

/*--------------------------------------------------------------------------
 * Bootstrap
 * 2018-03-12
 */

.table-hover>tbody>tr:hover{
    background-color: #fbfffd;
}

.bootstrap-select .dropdown-menu ul,
.bootstrap-select .dropdown-menu ul li {
    margin: 0px !important;
    padding: 0px !important;

}

.bootstrap-select .dropdown-menu ul li {
    border: none !important;
}

.bootstrap-select .dropdown-menu ul li {
    border: none !important;
}

.bootstrap-select .dropdown-menu ul li a:hover {
    background-color: #ebf4f8 !important;
}

.bootstrap-select .bs-searchbox {
    font-size: .9em;
    line-height: 1em;
}

.bootstrap-select > .dropdown-toggle {
    padding: 4px 10px;
}

.daterangepicker .ranges ul {
    width: 100%;
    overflow: hidden;
}

/* bootstrap-switch */
.input-group .btn-group{
    position:relative;
    right: 1px;
}

.bootstrap-switch {
    left: -1px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

/* calendar */
.fc-today{
    background: #fbfffd !important;
}
.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-helper-skeleton td{
    border-color: #ddd;
}


/* elfinder */
.elfinder-toast .toast-success {
    visibility: hidden;
}

/* graphs */

.lego > div{
    width: auto;
    border-right: 10px solid #5eb2d9;
}

.google-visualization-tooltip{
    display:none;
}

/* img */
.nice-shadow {
    -webkit-box-shadow: 2px 2px 2px 3px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 2px 2px 2px 3px #ccc; /* Firefox 3.5 - 3.6 */
    box-shadow: 2px 2px 2px 3px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

.nice-minimal {
    -webkit-box-shadow: 2px 2px 2px 1px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 2px 2px 2px 1px #ccc; /* Firefox 3.5 - 3.6 */
    box-shadow: 2px 2px 2px 1px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

/* remarks */

.remark ~ .remark{
    border-top: 1px solid #dedede;
    margin-top: 7px;
    padding-top: 10px;
}

.img-sqircle {
    border-radius: 7px;
}

/* attention */
.highlight {
    background-color: #e3f2f8;
}

/* modal form elements */
.colorpicker{
    position: absolute !important;
    z-index: 1045 !important;
}

/* menus */
.dropdown-menu{
    padding: 0px 0px 0px 0px;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(100,100,100,0.4);
    -moz-box-shadow: 0px 1px 4px 0px rgba(100,100,100,0.4);
    box-shadow: 0px 1px 4px 0px rgba(100,100,100,0.4);
}
.dropdown-header:not(:first-child){

}
.dropdown-header:not(:first-child) {
    border-top: 1px solid #dedede;
}
.dropdown-header{
    border-bottom: 1px solid #dedede;
    background-color: #eeeeee;
}

/* moves search */
.nav form .form-group{
    margin:0px !important;
}
.nav form .form-group *{
    border:none;
}
.nav form .form-group button{
    border-left: 1px solid #cccccc;
}
