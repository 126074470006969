#wizard_step_count{
    display:block;
}

#wizard_step_count * {
    display: inline-block;
    vertical-align: top;
}

#wizard_step_count > span {
    height: 45px;
    width: 45px;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    background-color: #f5f5f5;
}

#wizard_step_count > span.active {
    background-color: #4088ac;
}

#wizard_step_count > span:not(.active) {
    color: #cccccc;
    border: 2px solid #cccccc;
}

#wizard_step_count div {
    position: relative;
    top: 23px;
    width: 65px;
    border-top: 2px solid #cccccc;
    margin-right: -2px;
    margin-left: -2px;
}

#wizard_step_count div:last-child {
    clear: both;
    float: none;
    border-top: none;
}

